<template>
  <v-col cols="12">
    <p>Payments</p>
    <v-card elevation="0" outlined>
      <v-card-text
        class="d-flex sm-align-center justify-space-between pb-2"
        :class="{ 'flex-column': $vuetify.breakpoint.xs }"
      >
        <div>
          <h4 class="dark--text font-weight-medium fz-16">Current balance</h4>
          <p :class="{'mb-0':$vuetify.breakpoint.smAndUp }">${{ balance }}</p>
        </div>
        <v-btn
          color="primary"
          elevation="0"
          @click="addCredit = true"
          :block="$vuetify.breakpoint.xs"
          :outlined="$vuetify.breakpoint.smAndUp"
          >Add Credit
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="d-flex justify-space-between py-6">
        <h4 class="dark--text font-weight-medium fz-16">Billing history</h4>
        <v-btn
          small
          icon
          to="/billing-history"
        >
        <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-layout
        justify-space-between
        class="sm-align-center pa-4"
        :class="{ 'flex-column': $vuetify.breakpoint.xs }"
      >
        <div>
          <p class="body-1 mb-0 font-weight-medium fz-16">Auto-recharge</p>
          <p  :class="{'mb-0':$vuetify.breakpoint.smAndUp}" class="caption">When balance falls below $2.00</p>
        </div>
        <v-spacer></v-spacer>
        <v-select
          v-model="autoRecharge"
          :items="items"
          :menu-props="{ contentClass: 'settings-select' }"
          attach
          dense
          class="autoRechargeSelect"
          outlined
          hide-details
          width="50"
        >
          <template v-slot:item="{ item }">
            <v-icon class="me-3 ms-1">{{ item.icon }}</v-icon>
            {{ item.text }}
          </template>
        </v-select>
        <div class="select-bg"></div>
      </v-layout>
      <v-divider></v-divider>
      <v-card-text class="d-flex align-center justify-space-between">
        <div>
          <h4 class="dark--text font-weight-medium fz-16">Calling rates</h4>
          <v-subheader class="px-0"
            >Look up calling rates by country</v-subheader
          >
        </div>
        <v-btn icon elevation="0" @click="$router.push({ name: 'CallingRates' })">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>

    <!-- Add Credit Dialog-->
    <v-dialog v-model="addCredit" width="600">
      <v-card max-height="276px">
        <v-card-title class="body-1">
          Add credit
          <v-spacer></v-spacer>
          <v-btn @click="addCredit = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="fz-12 pb-0">
          Use credit to make paid calls with Freefone Voice.
<!--           <a href="#" class="text-decoration-none info--text">Learn more</a>. -->
        </v-card-text>

        <div class="px-8">
          <v-radio-group v-model="creditAmount" row>
            <v-radio label="$10.00" value="10"></v-radio>
            <v-radio label="$25.00" value="25"></v-radio>
            <v-radio label="$50.00" value="50"></v-radio>
          </v-radio-group>
        </div>
        <!-- <v-card-text v-if="userProfile.balance" class="fz-12 pb-0">
          Your current balance is $ {{ userProfile.balance }}. The account balance cannot exceed
          $70.00.
        </v-card-text> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="addCredit = false"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="!creditAmount"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="creditStore"
            >Add credit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'settingsPayments',
  props: ['profileData'],
  data: () => {
    return {
      profile: {},
      addCredit: false,
      creditAmount: '10',
      showBillingHistory: false,
      autoRecharge: 'Off',
      items: [
        {
          text: 'Off',
          icon: 'mdi-bell-off'
        },
        {
          text: 'Notify me via email',
          icon: 'mdi-email-alert'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userProfile']),
    balance () {
      return this.profile.balance ? this.profile.balance.toFixed(2) : 0
    }
  },
  methods: {
    ...mapActions(['editUserProfile', 'createPayment']),
    async profileUpdate(key, value) {
      try {
        await this.editUserProfile({ [key]: value })
      } catch (err) {
        throw new Error(err)
      }
    },
    async creditStore() {
      try {
        const data = await this.createPayment({ amount: +this.creditAmount })
        if (data.payment_url) {
          this.addCredit = false
          window.location.href = data.payment_url
          return
        }
        window.scrollTo(0, 101)
      } catch (err) {
        throw new Error(err)
      }
    }
  },
  watch: {
    profileData() {
      this.profile = this.profileData
    }
  }
}
</script>

<style scoped>
.autoRechargeSelect{
  max-width: fit-content;
}

.v-btn {
  text-transform: none;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.icon-wrapper {
  background-color: rgba(127, 165, 232, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list-item__subtitle {
  white-space: normal !important;
}

@media (max-width: 575.98px) {
  .autoRechargeSelect {
    max-width: 100%;
  }
}
</style>
<style lang="scss" scoped>
  .autoRechargeSelect .v-menu__content {
    min-width: 190px !important;
    position: absolute;
    display: inline-block;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 104px;
    top: 0px !important;
    left: 0px !important;
    margin-left: -29px !important;
    margin-top: 40px !important;
  }
</style>
