<template>
  <v-col cols="12">
    <p class="font-weight-regular">Privacy & Terms</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item class="body-2 primary--text font-weight-medium">
          <a href="https://www.freefone.app/privacy" style="text-decoration: none;" target="_blank">Privacy</a>
        </v-list-item>
        <v-divider class="mx-4"></v-divider>
        <v-list-item class="body-2 primary--text font-weight-medium">
          <a href="https://www.freefone.app/TermsofUsePage" style="text-decoration: none;" target="_blank">Terms of Service</a>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'settingsPrivacy'
}
</script>
