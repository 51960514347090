<template>
  <v-col cols="12">
    <p>Messages</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Forward messages to email
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >{{ profile.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            v-model="profile.sms_to_email"
            :input-value="profile.sms_to_email"
            @change="hideSmsToEmailHandler"
          ></v-switch>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'settingsMessages',
  props: ['profileData'],
  data: () => {
    return {
      profile: {
        forward_email: false
      }
    }
  },
  methods: {
    ...mapActions(['hideSmsToEmail', 'showAlert']),
    async hideSmsToEmailHandler(value) {
      try {
        await this.hideSmsToEmail(value)

        this.profile.sms_to_email = value

        // this.showAlert({ message: 'SMS to email visibilty was changed' })
      } catch (err) {
        this.profile.sms_to_email = !value
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    }
  },
  watch: {
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    }
  }
}
</script>
