var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.query.user)?_c('v-row',{staticClass:"mt-4",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"sm-margin-top-25px",attrs:{"cols":"12","sm":"1","md":"1"}},[_c('div',[_c('v-btn',{staticClass:"d-none d-md-inline-block",staticStyle:{"height":"38px"},attrs:{"color":"primary","block":"","outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left")]),_vm._v(" Back ")],1)],1)])],1):_vm._e(),_c('h1',{staticClass:"mb-4 mt-4"},[_vm._v("Invoice Listing")]),(false)?_c('v-row',{staticClass:"mt-4",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"sm-margin-top-25px",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-text-field',{attrs:{"dense":"","type":"email","solo":"","clearable":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"w-max mt-2px"},[_vm._v("Email:")])]},proxy:true}],null,false,1930187069),model:{value:(_vm.filter.email),callback:function ($$v) {_vm.$set(_vm.filter, "email", $$v)},expression:"filter.email"}})],1),_c('v-col',{staticClass:"sm-margin-top-25px",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('div',[_c('v-btn',{staticClass:"text-capitalize confirm-btn",staticStyle:{"height":"38px"},attrs:{"color":"primary","block":""},on:{"click":_vm.getData}},[_vm._v(" Search ")])],1)])],1):_vm._e(),(false)?_c('v-row',{staticStyle:{"margin-top":"-20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-key":"id","server-items-length":_vm.pagination.total,"options":{
           page: _vm.pagination.currentPage,
           itemsPerPage: _vm.pagination.take
        },"footer-props":{
          'items-per-page-options': [10, 20, 50, 100]
        }},on:{"update:options":_vm.getData},scopedSlots:_vm._u([{key:"item.payOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateOnly(item.payOn) || '-')+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" $"+_vm._s(item.amount)+" ")]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.email)+" ")]}}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }