<template>
  <div>
    <h1 class="mb-4 mt-4">User Listing</h1>
    <v-row class="mt-4" style="width: 100%">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="filter.type"
          :items="[{ label: 'All', value: '' }, { label: 'Free', value: 'free' }, { label: 'Paid', value: 'paid' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Type:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-select
          v-model="filter.status"
          :items="[{ label: 'All', value: '' }, { label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Status:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-text-field
          v-model="filter.email"
          dense
          type="email"
          solo
          clearable
          @input="changeFilter('email', $event)"
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ capitalizeFirstLetter(item.type) }}
          </template>
          <template v-slot:[`item.number`]="{ item }">
            {{ item.number || '-' }}
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.is_active,
                'red-lighten': !item.is_active
              }"
              small
              class="text-capitalize"
            >
              <span v-if="item.is_active">Active</span>
              <span v-else>Inactive</span>
            </v-chip>
          </template>
          <template v-slot:[`item.deactivated_on`]="{ item }">
            {{ formatDate(item.deactivated_on) || '-' }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleStatus(item)"
                  >
                    <v-icon color="#919EAB">{{ item.is_active ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_active ? 'Deactivate' : 'Activate' }}</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminPayment', item)"
                  >
                    <v-icon color="#919EAB">mdi-credit-card-outline</v-icon>
                  </v-btn>
                </template>
                <span>View Payment</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="$router.push({ name: 'AdminInvoice', query: { user: item.uuid } })"
                  >
                    <v-icon color="#919EAB">mdi-receipt-text</v-icon>
                  </v-btn>
                </template>
                <span>View Invoice</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminLogonHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-login</v-icon>
                  </v-btn>
                </template>
                <span>Login History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminNumber', item)"
                  >
                    <v-icon color="#919EAB">mdi-cellphone-text</v-icon>
                  </v-btn>
                </template>
                <span>Number History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminCallHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-phone-log</v-icon>
                  </v-btn>
                </template>
                <span>Call History</span>
              </v-tooltip>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goTo('AdminSmsHistory', item)"
                  >
                    <v-icon color="#919EAB">mdi-message-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>SMS History</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'

export default {
  name: 'Users',
  data: () => ({
    loading: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'created_at',
      type: '',
      status: '',
      email: ''
    },
    headers: [
      {
        text: 'Registered On',
        align: 'start',
        sortable: false,
        value: 'created_at',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Number',
        align: 'start',
        sortable: false,
        value: 'number',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'is_active',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Deactivated On',
        align: 'start',
        sortable: false,
        value: 'deactivated_on',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    timeoutTyping: null,
    timeoutTypingIndex: null
  }),
  created () {
    // this.getData()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  methods: {
    ...mapActions('admin', ['getAdminUsers', 'deactivateAdminUser']),
    ...mapActions(['showAlert']),
    capitalizeFirstLetter,
    goTo (name, item) {
      this.$router.push({ name, query: { user: item.uuid, email: btoa(item.email) } })
    },
    preventOverloading () {
      if (this.timeoutTyping) return
      this.timeoutTyping = true
      this.timeoutTypingIndex = setTimeout(() => {
        this.getData()
        clearTimeout(this.timeoutTypingIndex)
        this.timeoutTyping = null
      }, 600)
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        const res = await this.getAdminUsers(payload)
        this.pagination.total = res.total
        this.items = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    },
    async changeFilter (type, $event) {
      this.filter[type] = $event ? $event.toString() : ''
      if (type !== 'email' || (type === 'email' && !$event)) {
        await this.getData()
        return
      }
      this.preventOverloading()
    },
    async toggleStatus (item) {
      try {
        this.loading = true
        await this.deactivateAdminUser({ enable: item.is_active, id: item.uuid })
        await this.getData()
        this.showAlert({ message: 'User status updated' })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        this.getData()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
