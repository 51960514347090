<template>
  <div>
    <v-row class="mt-4" style="width: 100%" v-if="$route.query.user">
      <v-col cols="12" sm="1" md="1" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            block
            @click="$router.back()"
            class="d-none d-md-inline-block"
            outlined
          >
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <h1 class="mb-4 mt-4">Payment Listing</h1>
    <v-row class="mt-4" style="width: 100%" v-if="!$route.query.user">
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-autocomplete
          v-model="filter.email"
          :items="itemsUsers"
          :loading="loadingUsers"
          :search-input.sync="searchUsers"
          hide-no-data
          hide-selected
          item-text="email"
          item-value="email"
          label=""
          solo
          dense
          clearable
          placeholder=""
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template v-slot:[`item.payOn`]="{ item }">
            {{ formatDateOnly(item.payOn) || '-' }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            ${{ item.amount }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.user.email }}
          </template>
          <template v-slot:[`item.payPoint`]="{ item }">
            {{ formatPurpose(item.payPoint) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'

export default {
  name: 'PaymentListing',
  data: () => ({
    loading: false,
    loadingUsers: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'pay_on',
      email: '',
      user_uuid: ''
    },
    headers: [
      {
        text: 'Paid On',
        align: 'start',
        sortable: false,
        value: 'payOn',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Transaction ID',
        align: 'start',
        sortable: false,
        value: 'transactionId',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Amount',
        align: 'start',
        sortable: false,
        value: 'amount',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Purpose',
        align: 'start',
        sortable: false,
        value: 'payPoint',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    itemsUsers: [],
    searchUsers: null
  }),
  created () {
    const email = this.$route.query.email
    if (email) {
      this.searchUsers = atob(email)
      this.filter.email = atob(email)
      return
    }
    this.getDataUsers()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  watch: {
    searchUsers () {
      if (this.loadingUsers) return
      this.getDataUsers()
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminPayments', 'getAdminUsers']),
    ...mapActions(['showAlert']),
    capitalizeFirstLetter,
    formatPurpose (purpose) {
      if (!purpose) return ''
      return purpose.split('_').map(capitalizeFirstLetter).join(' ')
    },
    removeUrlFilter () {
      if (this.$route.query.email) {
        this.$router.replace({ name: 'AdminPayment' })
        this.getData()
      }
    },
    async getDataUsers () {
      try {
        this.loadingUsers = true
        const payload = {
          take: 200,
          email: this.searchUsers
        }
        const res = await this.getAdminUsers(payload)
        this.itemsUsers = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingUsers = false
      }
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        const res = await this.getAdminPayments(payload)
        this.pagination.total = res.total
        this.items = res.data.map(item => ({ ...item, payment_gateway: 'Stripe' }))
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
