<template>
  <v-container class="my-auto">
    <v-row class="d-flex justify-center">
      <div class="numberSection text-center">
        <v-img
          :src="require('../assets/' + responseAlertImage)"
          contain
          width="40"
          height="40"
          max-width="min-content"
          class="mb-6 mx-auto"
        />
        <h4 class="dark--text verifySuccess">{{ responseAlertMessage }}</h4>
        <v-btn
          @click="$router.push('/login')"
          color="primary"
          class="mt-12 text-none"
          depressed
        >
          Login
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmEmail',
  data: () => ({
    token: null,
    responseAlert: false,
    responseAlertMessage: '',
    responseAlertImage: ''
  }),
  mounted() {
    this.verify()
  },
  methods: {
    ...mapActions(['confirmEmail']),
    async verify() {
      try {
        await this.confirmEmail(this.$route.params.token)
        this.responseAlertMessage = 'User was confirmed successfully'
        this.responseAlertImage = 'svg/verify-success.svg'
      } catch (err) {
        this.responseAlertMessage = err
        this.responseAlertImage = 'png/error.png'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.numberSection {
  width: 100%;
  max-width: 560px;
}
</style>
