<template>
  <v-container class="my-auto">
    <v-row v-if="!responseAlert">
      <v-col cols="12" md="6" class="mx-auto mt-16 d-flex flex-column align-center">
        <h4 class="display-1 text-center dark--text">Password Recovery</h4>
        <p class="body-1 header-p muted--text text-center mt-2">
          Enter the email address to which we will send an email to reset
          your&nbsp;password
        </p>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-10 dark--text reset-password-form"
          lazy-validation
        >
          <v-layout align-center class="mb-2">
            <p class="body-1 mb-0">Email</p>
          </v-layout>
          <v-text-field
            background-color="#fafafa"
            v-model="email"
            :rules="emailRules"
            flat
            outlined
            solo
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="primary"
            class="py-6 text-capitalize"
            block
            @click="passwordRecover"
          >
            Confirm
          </v-btn>

          <p class="mt-8 muted--text text-center">
            Already have an account?
            <v-btn
              text
              class="info--text px-0 text-capitalize"
              plain
              :ripple="false"
              :to="{ name: 'Login' }"
            >
              Sign in
            </v-btn>
          </p>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-else class="d-flex justify-center">
      <div class="numberSection text-center">
        <v-img
          :src="require('../assets/' + responseAlertImage)"
          contain
          width="40"
          height="40"
          max-width="min-content"
          class="mb-6 mx-auto"
        />
        <h4 class="dark--text verifySuccess">{{ responseAlertMessage }}</h4>
        <v-btn
          v-if="responseAlertImage === 'png/error.png'"
          color="primary"
          class="mt-12 text-none"
          style="width: 103px; height: 52px"
          depressed
          @click="$router.push('/login')"
        >
          Login
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPassword',
  data: () => ({
    valid: true,
    responseAlert: false,
    responseAlertMessage: '',
    responseAlertImage: '',
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ]
  }),
  created () {
    this.logout(false)
  },
  methods: {
    ...mapActions(['resetPassword', 'logout']),
    validate() {
      this.$refs.form.validate()
    },
    async passwordRecover() {
      try {
        await this.resetPassword({ email: this.email })
        this.responseAlertMessage = 'Email is being successfully sent'
        this.responseAlertImage = 'svg/verify-success.svg'
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 3000)
      } catch (err) {
        this.responseAlertMessage = err.error || err.message
        this.responseAlertImage = 'png/error.png'
      } finally {
        this.responseAlert = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reset-password-form {
  width: min(560px, 90vw);
}
.v-text-field::v-deep {
  &:not(.error--text) {
    .v-input__slot {
      height: 60px;
    }
    .v-input__slot fieldset {
      border: 1px solid #e3e3e3 !important;
    }
    &.v-input--is-dirty {
      .v-input__slot fieldset {
        border: 1px solid #27ae60 !important;
      }
      &.v-input--is-focused {
        .v-input__slot fieldset {
          border: 1px solid #5758b0 !important;
        }
      }
    }
  }
}
::v-deep .v-btn.v-btn--disabled {
  color: #fff !important;
}
.header-p{
  height: 20px;
  letter-spacing: -0.02em !important;
  font-style: normal;
}
.v-btn--outlined.light--text {
  border: 1px solid #d6d8e3;
  width: 100%;
}
@media (min-width: 960px) {
  .my-auto {
    max-width: calc(100% - 20px);
  }
}
</style>
