<template>
  <div>
    <v-row class="mt-4" style="width: 100%" v-if="$route.query.user">
      <v-col cols="12" sm="1" md="1" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            block
            @click="$router.back()"
            class="d-none d-md-inline-block"
            outlined
          >
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <h1 class="mb-4 mt-4">SMS History</h1>
    <v-row class="mt-4" style="width: 100%">
      <v-col cols="12" sm="4" md="3" class="">
        <v-autocomplete
          v-model="filter.email"
          :items="itemsUsers"
          :loading="loadingUsers"
          :search-input.sync="searchUsers"
          hide-no-data
          hide-selected
          item-text="email"
          item-value="email"
          label=""
          solo
          dense
          clearable
          placeholder=""
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-text-field
          v-model="filter.caller_id"
          @keypress="onlyNumbers($event)"
          dense
          type="text"
          solo
          clearable
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">From:</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-text-field
          v-model="filter.phone_number"
          @keypress="onlyNumbers($event)"
          dense
          type="text"
          solo
          clearable
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">To:</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row style="width: 100%">
      <v-col cols="12" sm="4" md="3" class="margin-top-25px">
        <v-select
          v-model="filter.dir"
          :items="[{ label: 'All', value: 'all' }, { label: 'Inbound', value: 'in' }, { label: 'Outbound', value: 'out' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Direction:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.email }}
          </template>
          <template v-slot:[`item.direction`]="{ item }">
            {{ item.direction === 'out' ? 'Outbound' : 'Inbound' }}
          </template>
          <template v-slot:[`item.rate`]="{ item }">
            {{ item.rate }}
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            {{ item.cost }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'

const headersFull = [
  {
    text: 'Time',
    align: 'start',
    sortable: false,
    value: 'createdAt',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'From',
    align: 'start',
    sortable: false,
    value: 'ani',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'To',
    align: 'start',
    sortable: false,
    value: 'dnis',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Direction',
    align: 'start',
    sortable: false,
    value: 'direction',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Rate',
    align: 'start',
    sortable: false,
    value: 'rate',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Message',
    align: 'start',
    sortable: false,
    value: 'text',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Cost',
    align: 'start',
    sortable: false,
    value: 'cost',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  }
]

const headersInboundOnly = [
  {
    text: 'Time',
    align: 'start',
    sortable: false,
    value: 'createdAt',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'From',
    align: 'start',
    sortable: false,
    value: 'ani',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'To',
    align: 'start',
    sortable: false,
    value: 'dnis',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Direction',
    align: 'start',
    sortable: false,
    value: 'direction',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  },
  {
    text: 'Message',
    align: 'start',
    sortable: false,
    value: 'text',
    class: ['text-caption', 'greyBuoyant'],
    cellClass: 'greyBuoyant'
  }
]

export default {
  name: 'CallHistory',
  components: {},
  data: () => ({
    loading: false,
    loadingUsers: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'createdAt',
      caller_id: '',
      phone_number: '',
      email: '',
      dir: 'all'
    },
    headers: [],
    items: [],
    itemsUsers: [],
    searchUsers: null
  }),
  created () {
    const email = this.$route.query.email
    if (email) {
      this.searchUsers = atob(email)
      this.filter.email = atob(email)
      return
    }
    this.headers = headersFull
    this.getDataUsers()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  watch: {
    searchUsers () {
      if (this.loadingUsers) return
      this.getDataUsers()
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminSmsHistory', 'getAdminUsers']),
    ...mapActions(['showAlert']),
    capitalizeFirstLetter,
    onlyNumbers(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    changeDate (type, event) {
      this.filter[type] = event || ''
    },
    removeUrlFilter () {
      if (this.$route.query.email) {
        this.$router.replace({ name: 'AdminSmsHistory' })
        this.getData()
      }
    },
    async getDataUsers () {
      try {
        this.loadingUsers = true
        const payload = {
          take: 200,
          email: this.searchUsers
        }
        const res = await this.getAdminUsers(payload)
        this.itemsUsers = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingUsers = false
      }
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        this.headers = ['all', 'out'].includes(this.filter.dir) ? headersFull : headersInboundOnly
        delete payload.dir
        const res = await this.getAdminSmsHistory({ params: payload, dir: this.filter.dir })
        this.pagination.total = res.total
        this.items = this.filter.dir === 'all' ? res.data : res.data.map(item => ({
          ...item,
          direction: this.filter.dir
        }))
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
.margin-top-25px {
  margin-top: -25px!important;
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
