<template>
  <v-container fluid class="pa-0 mt-8">
    <Navbar />
    <v-row class="ma-0">
      <v-col cols="12" md="5" class="mx-auto px-5">
        <v-btn
          :to="{ name: 'Settings' }"
          color="primary"
          class="mb-10"
          outlined
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back to settings
        </v-btn>
        <v-divider class="mb-3" />
        <div class="d-flex align-center">
          <span class="mr-2">Start From:</span>
          <date-picker disable-future @changeDate="changeDate" />
        </div>
        <v-divider class="mt-3" />
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          hide-default-footer
          disable-sort
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import DatePicker from '@/components/DatePicker'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'BillingHistory',
  components: {
    Navbar,
    DatePicker
  },
  data () {
    return {
      items: [],
      loading: false,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      headers: [
        {
          text: 'Date Description',
          align: 'start',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: true,
          value: 'amount'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'type'
        }
      ]
    }
  },
  watch: {
    startDate () {
      this.getHistory()
    }
  },
  created () {
    this.getHistory()
  },
  methods: {
    ...mapActions(['getBillingHistory']),
    changeDate (v) {
      this.startDate = v
    },
    async getHistory () {
      try {
        this.loading = true

        const filter = { startDate: this.startDate }

        const data = await this.getBillingHistory(filter)

        this.items = data.data
          .filter(e => e.type === 'top-up' || e.type === 'plan-fee')
          .map(item => ({
            createdAt: this.formatDate(item.createdAt),
            amount: item.amount + ' $',
            type: item.type
          }))
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
