<template>
  <v-container fluid class="pa-0 mt-8">
    <Navbar />
    <alert-status v-if="$store.state.alertStatus !== null" />
    <v-row class="ma-0">
      <v-col cols="12" md="5" class="mx-auto px-5">
        <v-btn
          :to="{ name: 'Settings' }"
          color="primary"
          class="mb-10"
          outlined
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back to settings
        </v-btn>
        <h1 class="font-weight-medium mb-4">
          Freefone calling rates
        </h1>
        <v-text-field
          v-model="search"
          label="Search the country/region you want to call"
          prepend-inner-icon="mdi-magnify"
          hide-details
          dense
          outlined
        />
        <div class="d-flex align-center justify-space-between my-4">
          <v-select
            v-model="billingCountry"
            :items="billingCountrys"
            item-text="label"
            item-value="value"
            menu-props="offsetY"
            solo
            dense
            outlined
          >
            <template v-slot:prepend-inner>
              <span class="w-max">Billing country:</span>
            </template>
          </v-select>
          <v-select
            v-model="currency"
            :items="currencyOptions"
            item-text="label"
            item-value="value"
            menu-props="offsetY"
            class="ml-4"
            solo
            dense
            outlined
          >
            <template v-slot:prepend-inner>
              <span class="w-max">Currency:</span>
            </template>
          </v-select>
        </div>
        <v-list>
          <v-list-item class="pt-2">
            <v-list-item-title class="fz-14 font-weight-medium black--text">Country/region</v-list-item-title>
            <v-list-item-subtitle class="text-right fz-14 font-weight-medium black--text">Rate per minute (USD)</v-list-item-subtitle>
          </v-list-item>
          <template v-for="(price, index) in computedPriceList">
            <v-divider v-if="index" :key="index + 'divider'" />
            <v-list-item :key="index + 'item'" class="py-2">
              <v-list-item-title v-if="price.type === 'letter'" class="fz-32">{{ price.letter }}</v-list-item-title>
              <template v-else>
                <v-list-item-title>{{ price.country_name }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">${{ price.call_rate }}</v-list-item-subtitle>
              </template>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import AlertStatus from '@/components/Alert'
import { mapActions } from 'vuex'

export default {
  name: 'CallingRates',
  components: {
    Navbar,
    AlertStatus
  },
  data: () => {
    return {
      loading: false,
      priceList: [],
      total: 0,
      search: '',
      billingCountry: 'us',
      billingCountrys: [
        { label: 'US & Others', value: 'us' }
      ],
      currency: 'usd',
      currencyOptions: [
        { label: 'USD', value: 'usd' }
      ]
    }
  },
  computed: {
    computedPriceList () {
      return this.search ? this.priceList.filter(e => !e.type && e.country_name.toLowerCase().includes(this.search.toLowerCase())) : this.priceList
    }
  },
  created () {
    this.loadRules()
  },
  methods: {
    ...mapActions(['getPriceList', 'showAlert']),
    async loadRules () {
      try {
        this.loading = true
        const data = await this.getPriceList()
        if (!data.data.length) return
        let letter = data.data[0].country_name[0]
        this.priceList.push({ type: 'letter', letter })
        data.data.forEach(price => {
          if (price.country_name[0] !== letter) {
            letter = price.country_name[0]
            this.priceList.push({ type: 'letter', letter })
          }
          this.priceList.push(price)
        })
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
