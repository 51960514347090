<template>
  <v-row>
    <v-col xs="4">
      <v-btn color="light" outlined class="py-6 font-weight-medium" @click="openSocialAuth('google')">
        <v-img
          :src="require('../assets/svg/google.svg')"
          contain
          width="24"
          height="24"
          max-width="min-content"
        />
        <span class="d-none d-sm-block ml-2">Google</span>
      </v-btn>
    </v-col>
    <v-col xs="4">
      <v-btn color="light" outlined class="py-6 font-weight-medium" @click="openSocialAuth('fb')">
        <v-img
          :src="require('../assets/svg/facebook.svg')"
          contain
          width="24"
          height="24"
          max-width="min-content"
        />
        <span class="d-none d-sm-block ml-2">Facebook</span>
      </v-btn>
    </v-col>
    <v-col xs="4">
      <v-btn color="light" outlined class="py-6 font-weight-medium" @click="openSocialAuth('apple')">
        <v-img
          :src="require('../assets/svg/apple.svg')"
          contain
          width="15.17"
          height="18"
          max-width="min-content"
        />
        <span class="d-none d-sm-block ml-2">Apple</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SocialAuth',
  data: () => ({
    urls: {
      google: '',
      fb: '',
      apple: ''
    }
  }),
  created () {
    this.loadSocialNetworks()
  },
  methods: {
    ...mapActions(['getSocialNetworks']),
    async loadSocialNetworks () {
      try {
        const google = await this.getSocialNetworks('google')
        this.urls.google = google.data.url

        const fb = await this.getSocialNetworks('fb')
        this.urls.fb = fb.data.url

        const apple = await this.getSocialNetworks('apple')
        this.urls.apple = apple.data.url
      } catch (err) {
        throw new Error(err)
      }
    },
    openSocialAuth (type) {
      window.location = this.urls[type]
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn--outlined.light--text {
  border: 1px solid #d6d8e3;
  width: 100%;
}
</style>
