<template>
  <v-container class="my-auto">
    <v-row>
      <v-col cols="12" md="6" class="mx-auto text-center">
        <v-icon x-large color="success" class="mb-5">mdi-check-circle</v-icon>
        <h4 class="display-1 text-center dark--text verifySuccess">
          Password is successfully modified
        </h4>
        <v-btn
          color="primary"
          class="py-6 mt-5 text-capitalize"
          :class="{ 'w-100': window.width < 575.98 }"
        >
          Continue
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import resizeMixin from '@/mixins/resize.mixin'

export default {
  name: 'CompleteResetPassword',
  mixins: [resizeMixin]
}
</script>
