<template>
  <v-col cols="12">
    <p>Web notifications</p>
    <v-card elevation="0" outlined>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium fz-16"
            >{{ access ? "To update your preference, visit your browser's settings." : "Web notifications are off. To turn notifications on, visit your browser's settings." }}
            </v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
import { notificationInit } from '../../utilities/web-notifications'

export default {
  name: 'settingsWebNotifications',
  data: () => ({
    access: false
  }),
  methods: {},
  mounted() {
    notificationInit(() => {
      this.access = true
    })
  }
}
</script>

<style>
.v-list-item__title {
  white-space: normal !important;
}
</style>
