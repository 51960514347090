<template>
  <div>
    <v-row class="mt-4" style="width: 100%" v-if="$route.query.user">
      <v-col cols="12" sm="1" md="1" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            block
            @click="$router.back()"
            class="d-none d-md-inline-block"
            outlined
          >
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <h1 class="mb-4 mt-4">Number Listing</h1>
    <v-row class="mt-4" style="width: 100%">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="filter.type"
          :items="[{ label: 'All', value: '' }, { label: 'Free', value: 'free' }, { label: 'Paid', value: 'paid' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Type:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-select
          v-model="filter.status"
          :items="[{ label: 'All', value: '' }, { label: 'Assigned', value: 'assigned' }, { label: 'Unassigned', value: 'unassigned' }]"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          solo
          dense
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Status:</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3" class="sm-margin-top-25px">
        <v-autocomplete
          v-model="filter.email"
          :items="itemsUsers"
          :loading="loadingUsers"
          :search-input.sync="searchUsers"
          hide-no-data
          hide-selected
          item-text="email"
          item-value="email"
          label=""
          solo
          dense
          clearable
          placeholder=""
        >
          <template v-slot:prepend-inner>
            <span class="w-max mt-2px">Email:</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="sm-margin-top-25px">
        <div>
          <v-btn
            style="height: 38px"
            color="primary"
            class="text-capitalize confirm-btn"
            block
            @click="getData"
          >
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :server-items-length="pagination.total"
          :options="{
             page: pagination.currentPage,
             itemsPerPage: pagination.take
          }"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100]
          }"
          @update:options="getData"
        >
          <template v-slot:[`item.started_on`]="{ item }">
            {{ formatDateOnly(item.started_on) }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ capitalizeFirstLetter(item.type) }}
          </template>
          <template v-slot:[`item.number`]="{ item }">
            {{ item.number || '-' }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="{
                'success-lighten': item.status === 'assigned',
                'red-lighten': item.status !== 'assigned'
              }"
              small
              class="text-capitalize"
            >
              <span>{{  capitalizeFirstLetter(item.status)}}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.released_on`]="{ item }">
            {{ formatDateOnly(item.released_on) || '-' }}
          </template>
          <template v-slot:[`item.action`]>
            <div>
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => {}"
                  >
                    <v-icon color="#919EAB">mdi-receipt-text</v-icon>
                  </v-btn>
                </template>
                <span>View Assignment History</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import removeObjectEmptyValues from '../../utilities/removeObjectEmptyValues'
import { capitalizeFirstLetter } from '../../utilities/formatter'

export default {
  name: 'NumberListing',
  data: () => ({
    loading: false,
    loadingUsers: false,
    pagination: {
      take: 10,
      skip: 0,
      total: 0,
      currentPage: 1
    },
    filter: {
      orderType: 'DESC',
      orderBy: 'started_on',
      type: '',
      status: '',
      email: ''
    },
    headers: [
      {
        text: 'Assigned On',
        align: 'start',
        sortable: false,
        value: 'started_on',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Number',
        align: 'start',
        sortable: false,
        value: 'number',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Unassigned On',
        align: 'start',
        sortable: false,
        value: 'released_on',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    items: [],
    itemsUsers: [],
    searchUsers: null
  }),
  created () {
    const email = this.$route.query.email
    if (email) {
      this.searchUsers = atob(email)
      this.filter.email = atob(email)
      return
    }
    this.getDataUsers()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutTypingIndex)
  },
  watch: {
    searchUsers () {
      if (this.loadingUsers) return
      this.getDataUsers()
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminNumbers', 'getAdminUsers']),
    ...mapActions(['showAlert']),
    capitalizeFirstLetter,
    removeUrlFilter () {
      if (this.$route.query.email) {
        this.$router.replace({ name: 'AdminNumber' })
        this.getData()
      }
    },
    async getDataUsers () {
      try {
        this.loadingUsers = true
        const payload = {
          take: 200,
          email: this.searchUsers
        }
        const res = await this.getAdminUsers(payload)
        this.itemsUsers = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingUsers = false
      }
    },
    async getData (params = {}) {
      try {
        this.loading = true
        if (Object.keys(params).length > 0 && params.page && params.itemsPerPage) {
          this.pagination.take = params.itemsPerPage
          this.pagination.skip = (params.page - 1) * params.itemsPerPage
        }
        const payload = {
          ...removeObjectEmptyValues(this.filter),
          take: this.pagination.take,
          skip: this.pagination.skip
        }
        const res = await this.getAdminNumbers(payload)
        this.pagination.total = res.total
        this.items = res.data
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.mt-2px {
  margin-top: 2px;
}
@media (max-width: 599px) {
  .sm-margin-top-25px {
    margin-top: -25px!important;
  }
}
</style>
